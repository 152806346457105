import React, { useState } from "react"
import GearIcon from "../../../fundamentals/icons/gear-icon"
import SearchIcon from "../../../fundamentals/icons/search-icon"
import Select from "../../../molecules/select/next-select/select"
import { arrayMove, rectSortingStrategy, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { closestCenter, DndContext, DragEndEvent, DragMoveEvent, PointerSensor, useDroppable, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import ListIcon from "../../../fundamentals/icons/list-icon";
import StackIcon from "../../../fundamentals/icons/stack-icon";

interface SortableItemProps {
  id: string;
  item: any;
}
export const SortableItem: React.FC<SortableItemProps> = ({ id, item }) => {
  const { setNodeRef, listeners, transform, transition } = useSortable({ id });

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition,
    // border: "1px solid red",
    // marginTop: "10px"
    cursor: "pointer",
    padding: 10,
    margin: 10,
    flex: 1
  };
  return (
    <div ref={setNodeRef} {...listeners} style={styles} >
      <div className="grid grid-cols-12 p-4 border border-l-[5px] border-blue-200 my-2">
        <div className="col-span-7">
          <div className="font-bold flex items-center">
            <div className="mr-3"><StackIcon size={20} /> </div>
            {item.title}
          </div>
        </div>

        <div className="col-span-3 flex items-center">
          <div className="text-gray-500 text-md ">{
            item.time
          }min</div>
        </div>

        <div className="col-span-2 flex items-center justify-end">
          A<div className="text-gray-500 text-md ">{
            item.amount
          }$</div>
        </div>
      </div>


    </div>
  );
};

const ServicesView = () => {
  const [items, setItems] = useState<any>({
    "Nails": [{
      id: 1,
      title: 'Manicure',
      time: 30,
      amount: 10
    }, {
      id: 2,
      title: 'Pedicure',
      time: 45,
      amount: 20
    }],
    "Hair": [{
      id: 3,
      title: 'Hair 1',
      time: 120,
      amount: 50
    }, {
      id: 4,
      title: 'Hair 2',
      time: 15,
      amount: 120
    }]
  });

  function findContainer(id: any) {
    // if (id in items) {
    //   return id;
    // }
    return Object.keys(items).find((key) =>
      items[key].find((item: any) => +item.id === +id)
    );
  }

  function dragEndFn(e: DragEndEvent) {
    const { active, over } = e;
    console.log(active, over)
    const overId = over?.id;
    const activeId = active?.id;
    const activeContainer = findContainer(activeId) || "";
    const overContainer = findContainer(overId) || "";
    const activeItems = items[activeContainer];
    const overItems = items[overContainer];

    if (!activeContainer) return;
    if (!overId) return;

    if (overContainer) {
      const overIndex = overItems.findIndex((item: any) => +item.id === +overId);
      console.log(overIndex);
      const activeIndex = activeItems.findIndex((item: any) => +item.id === +activeId);

      if (activeIndex !== overIndex) {
        setItems((items) => ({
          ...items,
          [overContainer]: arrayMove(overItems, activeIndex, overIndex)
        }));
      }
    }
  }

  function dragMoveFn(e: DragMoveEvent) {
    const { active, over } = e;
    console.log(e)
    // console.log(active, over)
    const overId = over?.id;

    // console.log(overId)
    if (!overId) return;
    const activeId = active?.id;
    const activeContainer = findContainer(activeId) || "";
    const overContainer = findContainer(overId) || "";
    // console.log(activeContainer, overContainer)

    if (!overContainer || !activeContainer) {
      return;
    }
    if (activeContainer !== overContainer) {
      const overIndex = (items[overContainer] as any[]).indexOf(item => +item.id === +overId);
      const newIndex =
        overIndex >= 0 ? overIndex : items[overContainer].length + 1;

      const data = {
        ...items,
        [activeContainer]: items[activeContainer].filter((item: any) => {
          return +item.id !== +activeId;
        }),
        [overContainer]: [
          ...items[overContainer].slice(0, newIndex),
          items[activeContainer].filter((item: any) => { return +item?.id === +activeId })[0],
          ...items[overContainer].slice(newIndex, items[overContainer].length)
        ]
      };
      // console.log(data)
      setItems(data);
    }
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    console.log(over);
    const { id } = active;
    if (!over) {
      return;
    }
    const { id: overId } = over;

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(overId);
      console.log({ activeIndex })

      let newIndex;
      // if (overId in prev) {
      //   // We're at the root droppable of a container
      //   newIndex = overItems.length + 1;
      // } else {
      //   const isBelowLastItem =
      //     over &&
      //     overIndex === overItems.length - 1 &&
      //     draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;

      //   const modifier = isBelowLastItem ? 1 : 0;

      //   newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      // }

      return prev;
      // return {
      //   ...prev,
      //   [activeContainer]: [
      //     ...prev[activeContainer].filter((item) => item !== active.id)
      //   ],
      //   [overContainer]: [
      //     ...prev[overContainer].slice(0, newIndex),
      //     items[activeContainer][activeIndex],
      //     ...prev[overContainer].slice(newIndex, prev[overContainer].length)
      //   ]
      // };
    });
  }

  return (<React.Fragment>
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-10 p-2 justify-between">
        <div>
          <div className="font-extrabold text-xl">Services menu</div>
        </div>

      </div>
      <div className="col-span-1">
        <Select placeholder='Options' size="md" options={[]}
          onChange={(e) => { console.log(e) }}
          value={null} />
        {/* <button className="bg-blue-400 text-white rounded-md p-2">Add client</button> */}
      </div>
      <div className="col-span-1">
        <button className="bg-black text-white rounded-md p-2">Add new</button>
      </div>

      <div className="grid gap-4 bg-gray-100 rounded-lg shadow col-span-12 p-4">
        <DndContext
          onDragEnd={dragEndFn}
          onDragOver={handleDragOver}
        // onDragMove={dragMoveFn}
        >
          <div className="min-h-[200px]">
            {Object.keys(items).map((e) => {
              return (
                <div
                  key={e}
                  className='mt-4'
                >
                  <div className="font-bold grid grid-cols-12">

                    <div className="col-span-10">
                      <div className="font-bold text-2xl flex items-center pl-[20px]">
                        <div className="mr-3"><StackIcon size={20} /> </div>
                        {e}
                      </div>
                    </div>
                  </div>
                  <SortableContext key={e} items={items[e]}>
                    <div className="min-h-[200px]">
                      {items[e].map((v) => (
                        <SortableItem key={v.id} id={v.id} item={v}></SortableItem>
                      ))}
                    </div>
                  </SortableContext>
                </div>
              )
            }
            )
            }
          </div>
        </DndContext>
      </div>

    </div>
  </React.Fragment>
  )

}

export default ServicesView