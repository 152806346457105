import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import Layout from "../../components/layout/Layout"
import AppointmentsView from "../../components/views/dashboard/nail/appointments"
import ServicesView from "../../components/views/dashboard/nail/services"
import AppointmentsPage from "./appointments"

const ServicePage = () => {
  return (
    <React.Fragment>
      <DndProvider backend={HTML5Backend}>
        <Layout>
          <ServicesView />
        </Layout>
      </DndProvider>
    </React.Fragment>
  )
}

export default ServicePage
